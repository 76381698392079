import { StyledImageLogo } from '../TheWestHeader.styled'
import { createHeaderLogo, LogoProps } from './createHeaderLogo'

const logoFile = require('./images/masthead-sound-telegraph.svg')

const props: LogoProps = {
    alt: 'Sound Telegraph',
    src: logoFile,
    heights: {
        desktop: 82,
        tablet: 70,
        mobile: 50,
    },
    ampInitialSize: {
        width: 618,
        height: 92,
    },
    verticalSpacing: 8,
}

export default createHeaderLogo(StyledImageLogo, props)
